import React from "react"
import Layout from "../components/layout";
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse, { domToReact } from "html-react-parser"
import EmailSubscribe from "../components/email-subscribe";
import EmailSubscribeSidebar from "../components/email-subscribe-sidebar";

const BlogPostTemplate = ({ data: { post }}) => {

  const image = getImage(post.featuredImage.node.localFile.childImageSharp.gatsbyImageData)
  const seoImage = post.featuredImage.node.localFile.childImageSharp.fluid.src
  const title = post.seo && post.seo.title ? post.seo.title : 'Carbon Switch'
  const description = post.seo && post.seo.metaDesc ? post.seo.metaDesc : 'We produce research and guides that help people and communities live more sustainably'

  // inject id into headings to be used in table of contents 
  // doing if for each b/c I don't know how to switch tag from H1 to H2, etc
  const IdOptions = {
    replace: ({ name, children }) => {
      
      if (!name) {
        return;
      }

      // check that it's a string so .replaceAll() doesn't spit error 
      // for example, headers that are hyperlinks too are objects so they break it.
      if (typeof domToReact(children) !== "string") {
        return;
      }

      if (name === "h1") {
        const innerText = domToReact(children)         // get text between H1 tags
        const idText = innerText.replaceAll(/\W/g,"-")   // replace spaces and non Latin characters with -
        return <h1 id={idText}>{innerText}</h1>;
      }

      if (name === "h2") {
        const innerText = domToReact(children)         // get text between H2 tags
        const idText = innerText.replaceAll(/\W/g,"-")   // replace spaces and non Latin characters with - 
        return <h2 id={idText}>{innerText}</h2>;
      }

      if (name === "h3") {
        const innerText = domToReact(children)         // get text between H3 tags
        const idText = innerText.replaceAll(/\W/g,"-")   // replace spaces and non Latin characters with - 
        return <h3 id={idText}>{innerText}</h3>;
      }

      if (name === "h4") {
        const innerText = domToReact(children)         // get text between H4 tags
        const idText = innerText.replaceAll(/\W/g,"-")   // replace spaces and non Latin characters with -
        return <h4 id={idText}>{innerText}</h4>;
      }

    }
  }

  // get all headings from HTML
  const headingOptions = {
    replace: ({ name, children }) => {

      const headersToTest = ["h1", "h2", "h3"]  // not including H4 since it's used too frequently
      
      // first check that innerText is a string
      // otherwise, if it's an object for example, it will crash page
      if (name && typeof domToReact(children) !== "string") {
        return <></>
      }

      // create ref link to any header types in headersToTest
      else if (name && headersToTest.includes(name)) {
        const innerText = domToReact(children)         // get text between tags (could be an object though)
        const idText = innerText.replaceAll(/\W/g,"-")   // replace spaces and non Latin characters with -
        
        return (
          <p className="mb-2 text-base">
            <a className="no-underline" href={"#" + idText}>{innerText}</a>
          </p>
        ) 
      }

      // return empty tags for anything that is not a header (e.g. p or img tags)
      else {
        return <></>
      }

    }
  }
  const allHeadings = parse(post.content, headingOptions)
  
 

  return (


    <Layout
      title={title}
      description={description}
      image={seoImage}
    >

      <div className="px-2 mt-20 sm:flex sm:flex-col sm:items-center">
          
        <header className="max-w-2xl">
          <h1>{parse(post.title)}</h1>
          <p className="mt-6 font-bold text-slate-800">By&nbsp;
            <Link className="text-slate-800" to={post.author.node.uri}>
            {post.author.node.name}
            </Link>
          </p>
          <GatsbyImage image={image} alt={parse(post.title)} className="my-6" />
        </header>

        <div id="body-container" className="lg:flex lg:flex-row">
          <div id="mobile-toc" className="lg:hidden bg-slate-100 p-4 mb-8">
            <h3 className="mb-2 mt-4 text-2xl">Table of contents</h3>
            {allHeadings}
          </div>
          <div id="body-content" className="max-w-xl px-4">
            {parse(post.content, IdOptions)}
          </div>
          <div id="sticky-container" className="hidden lg:block">
            <div id="desktop-toc" className="lg:pl-16 lg:max-w-sm lg:sticky lg:top-16">
              <h3 className="mb-0 text-2xl">Table of contents</h3>
              {allHeadings}
              <div id="newsletter-cta" className="mt-8 px-4 py-6 border bg-slate-50">
                <p className="mb-1 text-center font-semibold">Subscribe to our newsletter</p>
                <p className="text-center text-base">Get weekly tips on how to save energy and money.</p>
                <EmailSubscribeSidebar />
              </div>
            </div>
          </div>
        </div>

        <div id="newsletter-cta" className='bg-slate-100 w-full mt-20'>
          <div className="text-left sm:text-center flex flex-col mt-12 pt-6 pb-12 px-5 sm:px-3 md:px-6 lg:px-12">
              <div className="mx-auto max-w-2xl">
                <h3>Sign up to receive more stories like this in your inbox</h3>
                <p>
                  Every week we write a newsletter with stories just like this. 
                  We cover every thing from home energy to electrification to climate change. 
                  Not sure if you'll like it? Check out&nbsp;
                  <Link to="/newsletter/">our most recent stories here.</Link>
                </p>
              </div>
              <EmailSubscribe />
          </div>
        </div>

      </div>
          
    </Layout>

  )

}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    post: wpPost(id: {eq: $id}) {
      id
      content
      title
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
              fluid {
                src
              }
            }
          }
        }
      }
      seo {
        title
        metaDesc
      }
      author {
        node {
          uri
          name
        }
      }
    }
  }
`
