import React from 'react'
import { navigate } from "gatsby"


const EmailSubscribeSidebar = () => {
    
    return (
        <div className="mt-4">
            <form 
                name="article-sidebar" 
                onSubmit={ e => {
                e.preventDefault()
                navigate("/subscribe-thank-you/")
                }}
            >
                <input type="hidden" name="form-name" value="article-sidebar" />
                <input 
                className="inline-block w-full text-center py-2 mb-3 shadow-sm border border-slate-300 rounded-md"
                placeholder="Enter your email"
                type="email" 
                name="email"
                >
                </input>
                <button className="block sm:inline-block w-full py-2 px-8 bg-emerald-500 text-white font-normal rounded sm:mr-1">Sign up</button>
            </form>
        </div>
    )
}

export default EmailSubscribeSidebar